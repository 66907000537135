<template>
  <div class="FormSubmit">
    <div class="FormSubmit_form">
      <el-form
        ref="form"
        class="FormTable"
        :model="form"
        label-position="left"
        :rules="rules"
        label-width="auto"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名" prop="realname">
              <el-input
                v-model="form.realname"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="学号" prop="student_num">
              <el-input
                type="number"
                min="0"
                placeholder="请输入学号"
                v-model="form.student_num"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性别" prop="gender">
              <el-radio-group v-model="form.gender" size="medium">
                <el-radio border label="0">女</el-radio>
                <el-radio border label="1">男</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮箱" prop="email">
              <el-input
                v-model="form.email"
                placeholder="请输入邮箱"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="是否合作过研究或项目"
              prop="is_collaborate_project"
            >
              <el-radio-group
                v-model="form.is_collaborate_project"
                size="medium"
              >
                <el-radio border label="1">是</el-radio>
                <el-radio border label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="平均GPA" prop="average_gpa">
              <el-input
                type="number"
                min="0"
                placeholder="请输入平均GPA"
                v-model="form.average_gpa"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="专业排名(前Top)" prop="specialist_rank">
              <el-radio-group v-model="form.specialist_rank" size="medium">
                <el-radio border label="1">1%</el-radio>
                <el-radio border label="5">5%</el-radio>
                <el-radio border label="10">10%</el-radio>
                <el-radio border label="30">10% - 30%</el-radio>
                <el-radio border label="50">30% - 50</el-radio>
                <el-radio border label="100">50 - 100%</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="学院名称" prop="is_finance_school">
              <!-- <el-radio-group v-model="form.is_finance_school" size="medium">
                <el-radio border label="1">是</el-radio>
                <el-radio border label="0">否</el-radio>
              </el-radio-group> -->
              <el-input
                placeholder="请输入学院名称"
                v-model="form.is_finance_school"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="专业名称" prop="is_credit_student">
              <!-- <el-radio-group v-model="form.is_credit_student" size="medium">
                <el-radio border label="1">是</el-radio>
                <el-radio border label="0">否</el-radio>
              </el-radio-group> -->
              <el-input
                placeholder="请输入专业名称"
                v-model="form.is_credit_student"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否上过该老师的课" prop="isin_my_class">
              <el-radio-group v-model="form.isin_my_class" size="medium">
                <el-radio border label="1">是</el-radio>
                <el-radio border label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <!-- <el-col :span="12">
            <el-form-item
              label="是否合作过研究或项目"
              prop="is_collaborate_project"
            >
              <el-radio-group
                v-model="form.is_collaborate_project"
                size="medium"
              >
                <el-radio border label="1">是</el-radio>
                <el-radio border label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="开始上我的课的季度" prop="season">
              <el-select
                style="width:100%;"
                v-model="form.season"
                placeholder="请选择开始上我的课的季度"
              >
                <el-option
                  v-for="item in SeasonArr"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开始上我的课的时间" prop="stu_year">
              <el-date-picker
                style="width:100%;"
                v-model="form.stu_year"
                type="year"
                format="yyyy年"
                value-format="yyyy"
                :picker-options="pickerOptions"
                placeholder="选择年"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="请选择教师" prop="teacher_id">
              <el-select
                style="width:100%"
                v-model="form.teacher_id"
                placeholder="请选择教师"
                filterable
              >
                <el-option
                  v-for="item in teacherList"
                  :key="item.teacher_id"
                  :label="`${item.realname} [${item.teacher_code}]`"
                  :value="item.teacher_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="推荐码" prop="referral_code">
              <el-input
                v-model="form.referral_code"
                placeholder="请输入推荐码"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否写课程论文" prop="is_dissert">
              <el-radio-group
                @change="IsDissertChange"
                v-model="form.is_dissert"
                size="medium"
              >
                <el-radio border label="1">是</el-radio>
                <el-radio border label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.is_dissert == 1">
            <el-form-item label="所写论文名称" prop="paper_title">
              <el-input
                placeholder="请输入所写论文名称"
                :validate-event="form.is_dissert == 1 ? true : false"
                v-model="form.paper_title"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申请学校数量" prop="apply_school_num">
              <el-input
                type="number"
                min="0"
                placeholder="请输入申请学校数量"
                v-model="form.apply_school_num"
                @change="ChangeApplySchoolNum"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <template v-if="form.apply_school_num > 0">
          <el-row v-for="(item, index) in form.apply_school_num" :key="index">
            <el-col :span="12">
              <el-form-item
                :label="'申请学校' + (index + 1)"
                :prop="`apply_schools[${index}].apply_school`"
                :rules="[
                  {
                    required: true,
                    message: '请输入申请学校',
                    trigger: 'blur'
                  }
                ]"
              >
                <el-input
                  placeholder="请输入申请学校"
                  v-model="form.apply_schools[index].apply_school"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="'申请专业' + (index + 1)"
                :prop="`apply_schools[${index}].specialty`"
                :rules="[
                  {
                    required: true,
                    message: '请输入申请专业',
                    trigger: 'blur'
                  }
                ]"
              >
                <el-input
                  placeholder="请输入申请专业"
                  v-model="form.apply_schools[index].specialty"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <el-row>
          <el-col :span="24">
            <el-form-item label="正文一" prop="body_text_1">
              <el-input
                :rows="6"
                placeholder="请输入推荐信正文"
                type="textarea"
                @input="descInput1"
                v-model="form.body_text_1"
              ></el-input>
              <span
                class="numberV"
                style="position: absolute; right: 10px; bottom: 0"
                >{{ txtVal1 }}/160</span
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="正文二" prop="body_text_2">
              <el-input
                :rows="6"
                placeholder="请输入推荐信正文"
                type="textarea"
                @input="descInput2"
                v-model="form.body_text_2"
              ></el-input>
              <span
                class="numberV"
                style="position: absolute; right: 10px; bottom: 0"
                >{{ txtVal2 }}/160</span
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="正文三" prop="body_text_3">
              <el-input
                :rows="6"
                placeholder="请输入推荐信正文"
                type="textarea"
                @input="descInput3"
                v-model="form.body_text_3"
              ></el-input>
              <span
                class="numberV"
                style="position: absolute; right: 10px; bottom: 0"
                >{{ txtVal3 }}/160</span
              >
            </el-form-item>
          </el-col>
        </el-row>
        <div class="Buttnon_Subimt" @click="FormSubimt">提 交</div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { submit_form, teacher_list } from "../../api/Administration.js";
export default {
  data() {
    return {
      form: {
        apply_school_num: 1,
        apply_schools: [
          {
            apply_school: "",
            specialty: ""
          }
        ],
        teacher_id: ""
        // letter_form: [],
      },
      teacherList: [],
      rules: {
        realname: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
        student_num: [
          { required: true, message: "请输入学号", trigger: "blur" }
        ],
        average_gpa: [
          { required: true, message: "请输入平均GPA", trigger: "blur" }
        ],
        apply_school_num: [
          {
            required: true,
            message: "请输入准备申请几个学校",
            trigger: "blur"
          }
        ],
        body_text_1: [
          { required: true, message: "请输入准备正文一", trigger: "blur" }
        ],
        body_text_2: [
          { required: true, message: "请输入准备正文二", trigger: "blur" }
        ],
        body_text_3: [
          { required: true, message: "请输入准备正文三", trigger: "blur" }
        ],
        gender: [{ required: true, message: "请选择性别", trigger: "change" }],
        season: [
          {
            required: true,
            message: "请选择开始上我的课的季度",
            trigger: "change"
          }
        ],
        stu_year: [
          {
            required: true,
            message: "请选择开始上我的课的时间",
            trigger: "change"
          }
        ],
        referral_code: [
          { required: true, message: "请输入推荐码", trigger: "blur" }
        ],
        is_dissert: [
          { required: true, message: "请选择是否写课程论文", trigger: "change" }
        ],
        paper_title: [
          { required: true, message: "请输入所写论文名称", trigger: "blur" }
        ],
        teacher_id: [
          {
            required: true,
            message: "请选择教师",
            trigger: "change"
          }
        ],
        specialist_rank: [
          {
            required: true,
            message: "请选择专业排名（前Top）",
            trigger: "change"
          }
        ],
        is_credit_student: [
          {
            required: true,
            message: "请选择是否是信用管理专业学生",
            trigger: "change"
          }
        ],
        isin_my_class: [
          {
            required: true,
            message: "请选择是否上过我的课",
            trigger: "change"
          }
        ],
        is_finance_school: [
          {
            required: true,
            message: "请选择是否是金融学院学生",
            trigger: "change"
          }
        ],
        is_collaborate_project: [
          {
            required: true,
            message: "请选择是否有合作过研究或项目",
            trigger: "change"
          }
        ],
        letter_type: [
          { required: true, message: "请选择推荐信类型", trigger: "change" }
        ],
        // letter_form: [
        //   {
        //     required: true,
        //     message: "请选择需要推荐信的形式",
        //     trigger: "change",
        //   },
        // ],
        apply_schools: {
          apply_school: [
            { required: true, message: "请输入申请学校", trigger: "blur" }
          ],
          specialty: [
            { required: true, message: "请输入申请专业", trigger: "blur" }
          ]
        }
      },
      txtVal1: 0,
      txtVal2: 0,
      txtVal3: 0,
      SeasonArr: [
        {
          val: "spring",
          label: "春"
        },
        {
          val: "summer",
          label: "夏"
        },
        {
          val: "autumn",
          label: "秋"
        },
        {
          val: "winter",
          label: "冬"
        }
      ],
      pickerOptions: {
        //disabled为函数，返回值为布尔值，
        disabledDate: time => {
          let minYear = new Date().getFullYear() - 30;
          return (
            time > Date.now() ||
            time.getTime() < new Date(JSON.stringify(minYear))
          );
        }
      }
    };
  },
  watch: {},
  created() {
    this.searchteacher();
  },
  methods: {
    check(x, str) {
      var z = 0;
      do {
        let reg3 = /\s/g;
        var x = reg3.exec(str);
        if (x != null) {
          z++;
        }
      } while (x != null);
      return z;
    },
    descInput1() {
      this.txtVal1 = this.form.body_text_1
        .replace(/\s/g, " ")
        .split(" ").length;
      if (this.form.body_text_1.replace(/\s/g, " ").split(" ").length >= 160) {
        this.txtVal1 = 160;
        this.form.body_text_1 = this.form.body_text_1
          .replace(/\s/g, " ")
          .split(" ")
          .slice(0, 160)
          .join(" ");
      }
    },
    descInput2() {
      this.txtVal2 = this.form.body_text_1
        .replace(/\s/g, " ")
        .split(" ").length;
      if (this.form.body_text_1.replace(/\s/g, " ").split(" ").length >= 160) {
        this.txtVal2 = 160;
        this.form.body_text_1 = this.form.body_text_1
          .replace(/\s/g, " ")
          .split(" ")
          .slice(0, 160)
          .join(" ");
      }
    },
    descInput3() {
      this.txtVal3 = this.form.body_text_1
        .replace(/\s/g, " ")
        .split(" ").length;
      if (this.form.body_text_1.replace(/\s/g, " ").split(" ").length >= 160) {
        this.txtVal3 = 160;
        this.form.body_text_1 = this.form.body_text_1
          .replace(/\s/g, " ")
          .split(" ")
          .slice(0, 160)
          .join(" ");
      }
    },
    ChangeApplySchoolNum(e) {
      if (e == 0) {
        this.form.apply_school_num = 1;
        return;
      } else {
        this.form.apply_school_num = Number(e);
        let CopeAll = JSON.parse(JSON.stringify(this.form.apply_schools));
        let apply_schools_obj = {
          apply_school: "",
          specialty: ""
        };
        // for(let i = 0; i < e;i++){
        CopeAll.push(apply_schools_obj);
        // }
        this.form.apply_schools = JSON.parse(JSON.stringify(CopeAll));
      }
    },
    // 选择是否写课程论文
    IsDissertChange(e) {
      if (e == 0) {
        this.form.paper_title = "";
      }
    },

    // 获取教师数据列表
    searchteacher() {
      teacher_list().then(res => {
        this.teacherList = res.data;
      });
    },
    FormSubimt() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$confirm("此操作将提交推荐信表单数据, 是否继续?", "提示", {
            confirmButtonText: "提交",
            cancelButtonText: "取消",
            type: "warning"
          })
            .then(() => {
              submit_form({
                submit_form: this.form
              }).then(res => {
                if (res.code == 200) {
                  this.form = {
                    apply_school_num: 1,
                    apply_schools: [
                      {
                        apply_school: "",
                        specialty: ""
                      }
                    ]
                  };
                  this.txtVal1 = 0;
                  this.txtVal2 = 0;
                  this.txtVal3 = 0;
                  this.$message({
                    message: res.msg,
                    type: "success"
                  });
                  const h = this.$createElement;
                  this.$notify({
                    title: "温馨提示",
                    message: h(
                      "i",
                      { style: "color: teal" },
                      "已提交推荐信申请，请等待委托老师审核，请勿重复申请。"
                    )
                  });
                  this.$router.push({
                    path: "/progres"
                  });
                }
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消提交"
              });
            });
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.FormSubmit {
  width: 100%;
  // height: calc(100vh - 80px);
  display: flex;
  .FormSubmit_box {
    flex: 1;
    height: 100%;
  }
  .FormSubmit_form {
    flex: 2;
    // height: 100%;
    text-align: left;
    background: url("../../assets/imgs/formbak.jpg");
    background-size: 80%;
    // background-position: center;
    padding: 20px;
    .FormTable {
      background-color: rgba(#fff, 0.9);
      margin: 0 auto;
      width: 70%;
      padding-bottom: 20px;
      // height: 100%;
      .Buttnon_Subimt {
        box-sizing: border-box;
        width: 215px;
        height: 60px;
        border: 10px solid transparent;
        transform: translate(0, 0px);
        margin: 0 auto;
        backface-visibility: hidden;
        animation: 0s ease 0s 1 normal none running none;
        border: 2px solid rgb(64, 158, 255);
        border-radius: 10px;
        color: rgb(64, 158, 255);
        line-height: 60px;
        text-align: center;
        cursor: pointer;
      }
      .Buttnon_Subimt:hover {
        color: #fff;
        background-color: rgb(64, 158, 255);
        font-weight: bold;
      }
    }
  }
  // .FormSubmit_form:after {
  //     content: "";
  //     width: 100%;
  //     height: 100%;
  //     position: absolute;
  //     left: 0;
  //     top: 0;
  //     /* 从父元素继承 background 属性的设置 */
  //     background: inherit;
  //     filter: blur(4px);
  //     z-index: 2;
  // }
}
</style>
